/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar:hover {
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.06);
}

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  border-radius: 20px;
  box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
}

::-webkit-scrollbar-thumb:active {
  border-radius: 20px;
  box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
}

.dark {
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 20px rgba(255, 255, 255, 0.24);
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 20px rgba(255, 255, 255, 0.37);
  }
}

.mdc-button .mat-icon+.mdc-button__label {
  margin-left: 10px;
}

.no-subscript.mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.flex-center {
  @apply flex items-center justify-center;
}

.flex-center-between {
  @apply flex items-center justify-between;
}

.page {
  @apply p-7 w-full;
}

.inner-page {
  @apply p-3 w-full;
}

.card {
  @apply rounded-lg shadow-md bg-card;
}

.form-no-subscript {
  .mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.tabs-no-body.mat-mdc-tab-group .mat-mdc-tab-body-content {
  display: none !important;
}

.tabs-no-px.mat-mdc-tab-group .mat-mdc-tab-body-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.tab-headers-no-mx.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container {
  margin: 0 !important;
}

.checkbox-right .mat-pseudo-checkbox {
  position: absolute;
  right: 0;
}

.cdk-overlay-container .mdc-menu-surface.mat-mdc-select-panel {
  padding-top: 0;
}

.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    background-color: white !important;
    border-bottom-width: 2px;
    border-bottom-style: solid;
  }

  &.error .mdc-snackbar__surface {
    border-bottom-color: var(--fuse-warn-500) !important;

    alert-message {
      color: var(--fuse-warn-500);
    }
  }

  &.success .mdc-snackbar__surface {
    border-bottom-color: var(--fuse-primary-600) !important;

    alert-message {
      color: var(--fuse-primary-600);
    }
  }
}

.select-paginator .mat-mdc-paginator-container {
  padding: 4px 16px;
  min-height: fit-content;

  .mat-mdc-paginator-range-actions {
    margin: 0;
  }

  .mat-mdc-paginator-range-label {
    margin: 0;
  }
}

.mdc-evolution-chip-set__chips {
  max-width: 100% !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}


@keyframes spinner-btn {
  to {
    transform: rotate(360deg);
  }
}

.spinner-btn {
  cursor: not-allowed !important;
  background-color: rgb(229, 229, 229) !important;

  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner-btn .8s linear infinite;
  }
}

.hidden-scroll::-webkit-scrollbar {
  display: none;
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
